import { ChangeEvent } from 'react';

export const maskPostalcode = (event: ChangeEvent<HTMLInputElement>) => {
  const matches = event.target.value.replace(/[^a-zA-Z\d\s]/g, '').match(/(\d{0,4})(\s?)([a-zA-Z]{0,2})/);

  if (!matches) return;

  const numbers = matches[1];
  const space = matches[2];
  const characters = matches[3].toUpperCase();
  const masked = numbers.length < 4 ? numbers : `${numbers}${space}${characters}`;

  return masked;
};
